<template>
  <AuctionHeader />
  <section class="bg-agrogo-green-2 sizeMinAllView">
    <!-- CARRUSEL Tipos de fincas -->
    <div class="md:hidden">
      <slider-type-animal />
    </div>
    <div class="hidden md:flex h-9"></div>
    <!-- CONTENEDOR IMAGEN PRINCIPAL Y CARRUSEL -->
    <div
      class="md:bg-white md:mx-8 lg:mx-16 md:pt-10 pt-2 grid grid-cols-1 md:grid-cols-2 md:overflow-hidden md:rounded-t-50 md:gap-x-3 lg:gap-x-5"
    >
      <div class="md:grid md:grid-cols-1 md:ml-6 lg:ml-12 md:bg-white">
        <div
          class="flex relative overflow-hidden rounded-t-2xl md:rounded-t-30"
        >
          <img
            class="w-full h-auto"
            src="@/assets/images/bgdetallesubastas2.jpg"
          />
          <div class="card-label-size-detail absolute">
            <p class="font-nexa font-normal text-xs text-white">Super</p>
            <p
              class="text-agrogo-green-6 size-star leading-none space-x-px md:text-xs md:leading-3"
            >
              <span class="icon-star"></span><span class="icon-star"></span
              ><span class="icon-star"></span><span class="icon-star"></span
              ><span class="icon-star text-agrogo-green-5"></span>
            </p>
          </div>
          <div
            class="flex items-center justify-center w-12 h-8 bg-agrogo-yellow-1 absolute rounded-bl-2xl right-0 md:rounded-bl-lg md:h-10 md:w-14"
          >
            <a class="icon-heart text-sm text-white md:text-lg"></a>
          </div>
          <a
            class="icon-isologo-agrogo--1 text-3xl text-white absolute right-0 bottom-0 pr-3 pb-1 opacity-70"
          ></a>
        </div>
        <!-- CARRUSEL IMAGENES -->
        <div class="w-auto h-full bg-agrogo-green-2 md:bg-agrogo-white lg:mb-0">
          <div
            class="items-center px-2 md:px-0 py-2 overflow-x-scroll flex space-x-2"
          >
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction8.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction2.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction3.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction4.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction5.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction6.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction7.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction8.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction5.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction4.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction2.jpg"
              />
            </div>
            <div class="card-carousel w-12 h-12 lg:w-16 lg:h-16">
              <img
                class="w-full h-full"
                src="@/assets/images/detailauction8.jpg"
              />
            </div>
          </div>
        </div>
        <!-- CONTENEDOR DESCRIPCION DESKTOP -->
        <div
          class="hidden md:flex items-center justify-start md:pl-3 lg:pl-10 mx-4 pb-16"
        >
          <a class="font-nexa lg:text-2xl text-agrogo-green-2"
            ><span class="font-black">Descripción </span>de la Subasta
          </a>
        </div>
      </div>
      <!-- TEXTO DEBAJO DETALLES DE FICA MOBILE -->
      <div class="md:hidden bg-agrogo-green-2 relative">
        <div class="flex flex-col items-start justify-center pt-1 pb-4 pl-5">
          <a class="font-dinpro text-sm text-agrogo-yellow-1 pb-2"
            >ID# <span class="text-agrogo-gray-1">321654456</span></a
          >
          <a class="font-nexa font-normal text-white text-xl text-left pb-2"
            >100 Novillas preñadas Brahman, Blancas
          </a>
          <a
            class="font-nexa font-normal text-agrogo-green-4 text-base leading-none"
            >Medellín, Antioquia</a
          >
        </div>
        <div class="grid grid-cols-2 pb-12 pl-5 gap-3">
          <div class="grid grid-cols-1 place-content-start place-items-start">
            <a class="font-dinpro font-medium text-base text-agrogo-yellow-1">
              Participantes</a
            >
            <a class="font-dinpro font-medium text-base text-white opacity-75">
              <span class="icon-Perfil text-agrogo-yellow-1 text-sm pr-2"></span
              >3</a
            >
          </div>
          <div class="grid grid-cols-1 place-content-start place-items-start">
            <a class="font-dinpro font-medium text-base text-agrogo-yellow-1">
              Precio Base</a
            >
            <a
              class="font-dinpro font-medium text-base text-agrogo-gray-1 opacity-75"
              ><span
                class="icon-signo-pesos leading-none text-sm text-agrogo-yellow-1"
              ></span>
              610.000.000</a
            >
            <a
              class="font-dinpro text-xs text-agrogo-gray-1 md:text-base text-right"
              >(Pesos colombianos)</a
            >
          </div>
          <div class="grid grid-cols-1 place-content-start place-items-start">
            <a class="font-dinpro font-medium text-base text-agrogo-yellow-1">
              Valor de la Puja</a
            >
            <a
              class="font-dinpro font-medium text-base text-agrogo-gray-1 opacity-75"
              ><span
                class="icon-signo-pesos leading-none text-sm text-agrogo-yellow-1"
              ></span>
              10.000.000</a
            >
            <a
              class="font-dinpro text-xs text-agrogo-gray-1 md:text-base text-right"
              >(Pesos colombianos)</a
            >
          </div>
          <div class="grid grid-cols-1 place-content-start place-items-start">
            <a class="font-dinpro font-medium text-base text-agrogo-yellow-1">
              Precio Actual</a
            >
            <a class="font-dinpro font-bold text-lg text-agrogo-gray-1"
              ><span
                class="icon-signo-pesos leading-none text-sm text-agrogo-yellow-1"
              ></span>
              700.000.000</a
            >
            <a
              class="font-dinpro text-xs text-agrogo-gray-1 md:text-base text-right"
              >(Pesos colombianos)</a
            >
          </div>
        </div>
      </div>
      <!-- <div class="md:hidden"><Detail /></div> -->
      <!-- <div class="md:hidden"><LogueadoPeroNoInscrito /></div> -->
      <!-- <div class="md:hidden"><ContadorPujaEnProceso /></div> -->
      <!-- <div class="md:hidden"><PujaAsignada /></div> -->
      <div class="md:hidden"><TiempoParaInscripcionFinalizado /></div>
      <!-- <div class="md:hidden"><InscripcionExitosa /></div> -->
      <!-- <div class="md:hidden"><GanadorDeLaSubasta /></div> -->
      <!-- TEXTO DETALLES DE FINCA DESKTOP-->
      <div class="hidden md:flex flex-col mr-6 lg:mr-12 bg-white pt-4 pl-3">
        <div class="flex flex-col items-start justify-center pb-4">
          <a class="font-dinpro font-normal text-base text-agrogo-green-4"
            >ID# <span class="text-agrogo-black-1">321654456</span></a
          >
          <a
            class="font-nexa font-normal text-agrogo-black-1 text-left text-2xl lg:text-3xl"
            >100 Novillas preñadas Brahman Blancas</a
          >
          <a
            class="font-nexa font-bold text-agrogo-green-4 lg:text-xl leading-none text-left"
            >Medellín, Antioquia</a
          >
          <p
            class="font-dinpro font-medium text-sm text-agrogo-gray-3 opacity-75"
          >
            <span class="icon-certificado text-agrogo-green-4 pr-1"></span>
            Vendedor Certificado
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-6">
          <div class="grid grid-cols-1 place-content-start place-items-start">
            <a class="font-dinpro font-medium text-base text-agrogo-green-4">
              Participantes</a
            >
            <a
              class="font-dinpro font-medium text-2xl text-agrogo-gray-3 opacity-75"
            >
              <span
                class="icon-Perfil text-agrogo-green-4 text-2xl pr-2 leading-none"
              ></span
              >3</a
            >
          </div>
          <div class="grid grid-cols-1 place-content-start place-items-start">
            <a class="font-dinpro font-medium text-base text-agrogo-green-4">
              Precio Base</a
            >
            <a
              class="font-dinpro font-medium text-2xl text-agrogo-gray-3 opacity-75"
              ><span
                class="icon-signo-pesos leading-none text-agrogo-green-4"
              ></span>
              610.000.000</a
            >
            <a class="font-dinpro text-xs text-agrogo-gray-3 text-right"
              >(Pesos colombianos)</a
            >
          </div>
          <div class="grid grid-cols-1 place-content-start place-items-start">
            <a class="font-dinpro font-medium text-base text-agrogo-green-4">
              Valor de la Puja</a
            >
            <a
              class="font-dinpro font-medium text-2xl text-agrogo-gray-3 opacity-75"
              ><span
                class="icon-signo-pesos leading-none text-agrogo-green-4"
              ></span>
              10.000.000</a
            >
            <a class="font-dinpro text-xs text-agrogo-gray-3 text-right"
              >(Pesos colombianos)</a
            >
          </div>
          <div class="grid grid-cols-1 place-content-start place-items-start">
            <a class="font-dinpro font-medium text-base text-agrogo-green-4">
              Precio Actual</a
            >
            <a
              class="font-dinpro font-bold text-2xl xl:text-3xl text-agrogo-gray-3"
              ><span
                class="icon-signo-pesos leading-none xl:text-2xl text-agrogo-green-4"
              ></span>
              700.000.000</a
            >
            <a class="font-dinpro text-xs text-agrogo-gray-3 text-right"
              >(Pesos colombianos)</a
            >
          </div>
        </div>
        <!-- <div class=""><Detail /></div> -->
        <!-- <div class=""><LogueadoPeroNoInscrito /></div> -->
        <!-- <div class=""><ContadorPujaEnProceso /></div> -->
        <!-- <div class=""><PujaAsignada /></div> -->
        <div class=""><TiempoParaInscripcionFinalizado /></div>
        <!-- <div class=""><InscripcionExitosa /></div> -->
        <!-- <div class=""><GanadorDeLaSubasta /></div> -->
      </div>
    </div>
    <!-- CONTENEDOR DESCRIPCION MOVIL -->
    <div class="items-center justify-center pb-4 pt-11 bg-white md:hidden">
      <a class="font-nexa text-lg text-agrogo-green-2"
        ><span class="font-black">Descripción </span>de la Subasta
      </a>
    </div>
    <!-- CONTAINER CARDS  -->
    <div class="md:px-10 lg:px-24 bg-white">
      <!-- CARD 1 UBICACION -->
      <div class="md:w-1/2 md:pr-5 md:py-0 md:mx-4">
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Raza</a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >Brahman</a
          >
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 bg-agrogo-gray-2 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Puro/ Comercial</a
          >
          <div
            class="grid grid-cols-1 place-content-center place-items-center cursor-pointer"
          >
            <div
              class="flex items-center justify-center bg-agrogo-yellow-2 rounded-lg w-6 h-6 md:w-8 md:h-8"
            >
              <span
                class="icon-see text-sm md:text-xl text-agrogo-green-5"
              ></span>
            </div>
            <p
              class="font-dinpro font-normal text-xs md:text-sm text-agrogo-gray-3"
            >
              Ver Registro
            </p>
          </div>

          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >Puro con registro, potrero</a
          >
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Tipo</a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >Macho cría
          </a>
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 bg-agrogo-gray-2 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Edad Promedio <br /><span class="font-normal text-xs"
              >(Meses)</span
            ></a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >8</a
          >
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Peso promedio x Animal
            <span class="font-normal text-xs">(Kg)</span></a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >200
          </a>
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7 bg-agrogo-gray-2"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Precio Promedio x Animal <br />
            <span class="font-normal text-xs">(Pesos Colombianos)</span></a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >$5.000.000
          </a>
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Número de Animales</a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >100</a
          >
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7 bg-agrogo-gray-2"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Precio Promedio x Kilo <br />
            <span class="font-normal text-xs">(Pesos Colombianos)</span></a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >$25.000
          </a>
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Peso Total <span class="font-normal text-xs">(Kg)</span></a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >2.600
          </a>
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7 bg-agrogo-gray-2"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Número de Hierros</a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >1
          </a>
        </div>
        <div
          class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-14 lg:pr-7"
        >
          <a
            class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
            >Enfermedad Defectos Físicos</a
          >
          <a
            class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
            >100% Sano
          </a>
        </div>
      </div>
    </div>
    <!-- CONTENEDOR COMPARTIR FINCA -->
    <div
      class="flex flex-col w-full h-auto pt-4 px-4 md:px-0 pb-10 md:items-center md:pt-12"
    >
      <a
        class="font-nexa font-normal text-white text-left text-sm space-x-2 cursor-pointer md:text-2xl md:space-x-4"
        ><span class="icon-share text-agrogo-yellow-1 leading-none"></span
        ><span class="font-black">Compartir </span>Subasta</a
      >
      <div class="w-full px-12 pt-5 container">
        <div class="flex items-center justify-between">
          <a
            @click="formEmailWhastapp"
            v-if="selectEmailWhastapp"
            class="font-nexa font-black text-xs text-agrogo-yellow-1 md:text-lg"
            ><span class="icon-whatsapp pr-1.5 md:pr-3"></span>Whatsapp</a
          >
          <a
            @click="formEmailWhastapp"
            v-if="selectEmailWhastapp"
            class="font-nexa text-xs text-white md:text-lg"
            ><span class="icon-mail pr-1.5 md:pr-3"></span>Email</a
          >
          <a
            @click="formEmailWhastapp"
            v-if="!selectEmailWhastapp"
            class="font-nexa text-xs text-white md:text-lg"
            ><span class="icon-whatsapp pr-1.5 md:pr-3"></span>Whatsapp</a
          >
          <a
            @click="formEmailWhastapp"
            v-if="!selectEmailWhastapp"
            class="font-nexa text-xs font-black text-agrogo-yellow-1 md:text-lg"
            ><span class="icon-mail pr-1.5 md:pr-3"></span>Email</a
          >
        </div>
        <div class="flex items-center justify-between"></div>
        <!-- INPUT Whatsapp -->
        <div class="flex flex-col pt-4 pb-3">
          <div
            v-if="selectEmailWhastapp"
            class="flex justify-between md:justify-start"
          >
            <div class="relative w-6/12 md:w-3/12 mr-2">
              <img :src="img" class="img-code" />
              <v-select
                :options="codes"
                v-model="selected"
                :reduce="(option) => option.id"
                class="style-chooser"
                label="phone_code"
              >
                <template #option="{ phone_code, path_img_iso_large, name }">
                  <div class="flex items-center justify-between">
                    <div class="flex">
                      <img
                        :src="path_img_iso_large"
                        :alt="phone_code"
                        class="mr-2"
                      />
                      <div class="font-medium">{{ phone_code }}</div>
                    </div>
                    <div class="font-medium">
                      <div v-if="name.length > 20">
                        {{ name.slice(0, 20) }}..
                      </div>
                      <div v-else>{{ name }}</div>
                    </div>
                  </div>
                </template>
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
            <div class="relative w-7/12 md:w-9/12">
              <div
                class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
              >
                <span class="icon-telefono text-agrogo-black-1 text-lg"></span>
              </div>
              <input
                v-model="$v.formData.telephone.$model"
                @blur="$v.formData.telephone.$touch()"
                id="telephone"
                name="telephone"
                type="text"
                class="input-login"
                :class="{ 'error-form': $v.formData.telephone.$error }"
              />
            </div>
          </div>

          <div v-if="!selectEmailWhastapp" class="relative">
            <div
              class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
            >
              <span class="icon-mail text-agrogo-black-1 text-lg"></span>
            </div>
            <input
              v-model="$v.formData.telephone.$model"
              @blur="$v.formData.telephone.$touch()"
              id="telephone"
              name="telephone"
              type="text"
              class="input-login"
              :class="{ 'error-form': $v.formData.telephone.$error }"
            />
          </div>

          <!-- CONTENEDOR DE BOTÓN COMPARTIR FINCA -->
          <div class="w-full mr-2">
            <button type="button" class="btn-login w-full mt-4">
              <span class="font-nexa font-bold">Enviar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTENEDOR FINCAS SIMILARES -->
    <div
      class="bg-agrogo-yellow-1 h-12 flex items-center justify-between pl-8 md:h-16 md:pl-24 lg:pl-32"
    >
      <a class="font-nexa text-agrogo-green-2 text-base text-left md:text-2xl"
        ><span class="font-black">Subastas </span>Similares</a
      >
    </div>
    <!-- CARDSFARMS -->
    <div
      class="bg-white grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 place-content-center place-items-center gap-2 md:gap-3.5 px-2 pt-2 pb-2 md:px-4 lg:px-4 md:pt-7 md:pb-8"
    >
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
    </div>
    <div class="bg-agrogo-yellow-2 h-2"></div>
  </section>
</template>

<script>
import AuctionHeader from "@/components/header/HeaderAuction";
import SliderTypeAnimal from "@/components/slider/SliderTypeAnimal";
import CardAuctions from "@/views/auction/cards/CardAuction";
import validationMixin from "@/mixins/validationMixin";
import loginRegisterMixin from "@/mixins/loginRegisterMixin";
import { required, minLength, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// import Detail from "@/views/auction/auctionsDetail/components/Detail";
// import LogueadoPeroNoInscrito from "@/views/auction/auctionsDetail/components/LogueadoPeroNoInscrito";
// import ContadorPujaEnProceso from "@/views/auction/auctionsDetail/components/ContadorPujaEnProceso";
// import PujaAsignada from "@/views/auction/auctionsDetail/components/PujaAsignada";
import TiempoParaInscripcionFinalizado from "@/views/auction/auctionsDetail/components/TiempoParaInscripcionFinalizado";
// import InscripcionExitosa from "@/views/auction/auctionsDetail/components/InscripcionExitosa";
// import GanadorDeLaSubasta from "@/views/auction/auctionsDetail/components/GanadorDeLaSubasta";
// import HistoryOfAuction from "./components/HistoryOfAuction";
export default {
  inject: ["provider"],
  mixins: [validationMixin, loginRegisterMixin],
  name: "Home",
  components: {
    SliderTypeAnimal,
    CardAuctions,
    AuctionHeader,
    // Detail,
    // LogueadoPeroNoInscrito,
    // ContadorPujaEnProceso,
    // PujaAsignada,
    TiempoParaInscripcionFinalizado,
    // InscripcionExitosa,
    // GanadorDeLaSubasta,
    // HistoryOfAuction,
  },

  data() {
    return {
      $v: useVuelidate(),
      formData: {
        telephone: "",
        email: "",
      },
      isBusy: false,
      repository: this.provider.authRepository,
      codes: [],
      selectEmailWhastapp: true,
    };
  },
  validations() {
    return {
      formData: {
        telephone: {
          required,
          minLength: minLength(7),
          maxLength: maxLength(15),
        },
      },
    };
  },
  methods: {
    formEmailWhastapp() {
      const me = this;
      if (me.selectEmailWhastapp) {
        me.selectEmailWhastapp = false;
      } else {
        me.selectEmailWhastapp = true;
      }
    },
  },
  mounted() {
    const me = this;
    me.loadCodeArea();
  },
};
</script>

<style lang="css" scoped></style>
